import request from '@/axios'
// 登录
export const loginApi=(data)=> request.post({ url: '/api/user/login', data })
// 登出
export const loginOutApi=(data)=> request.post({ url: '/api/signOut', data })
// 短信登录
export const phonelogin=(data)=> request.post({ url: '/api/user/phone/login', data })
//获取个人信息
export const getUserInfo=(data)=> request.post({ url: '/api/systems/user/info', data })
//密码登录
export const userlogin=(data)=> request.post({ url: '/api/user/login', data })
// 获取短信验证码
export const getPhoneCode=(data)=> request.post({ url: '/api/getPhoneCode', data })

// 获取用户权限
export const getUserAuth=()=> request.post({ url: '/api/systems/auth/authList'})

